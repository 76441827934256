var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","background-color":"transparent"}},[(_vm.$apollo.loading && !_vm.savedIndicator)?_c('Loading'):[_c('v-card-text',{attrs:{"id":"indicator-edit__card-text"}},[_c('Fields',{attrs:{"schema":{
          name: {
            type: 'string',
            label: 'Nombre',
            description: 'Debe ser único y reconocible.',
            sizeSmall: 12,
            sizeLarge: 6,
            newDesign: true
          },
          title: {
            type: 'string',
            label: 'Título',
            description: 'Opcional. Se mostrará en las vistas.',
            sizeSmall: 12,
            sizeLarge: 6,
            optional: true,
            newDesign: true
          },
          roles: {
            type: 'roleSelect',
            __graphQLType: '[ID]',
            label: 'Roles',
            fieldOptions: {
              multi: true
            },
            optional: true,
            newDesign: true
          },
          indicatorTypeId: {
            type: 'select',
            __graphQLType: 'ID',
            label: 'Tipo de indicador',
            fieldOptions: {
              options: _vm.availableIndicatorTypes
            },
            newDesign: true
          },
          link: {
            type: 'string',
            label: 'Link (ruta a vista o URL externa)',
            description:
              'Se pueden incluir parámetros usando la sintáxis :parametro',
            fieldOptions: {
              icon: 'attachment'
            },
            optional: true,
            newDesign: true
          },
          disableRefresh: {
            type: 'boolean',
            fieldType: 'checkbox',
            label: 'Desactivar auto-actualización',
            optional: true,
            newDesign: true
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.indicator),callback:function ($$v) {_vm.indicator=$$v},expression:"indicator"}}),(_vm.indicatorType)?[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-slide-y-transition',[_c('v-row',{attrs:{"no-gutters":true}},[(_vm.indicatorType.requireCollection)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicatorType.requireCollection)?_c('ComponentSelect',{key:"collectionId",attrs:{"name":"collectionId","schema":{
                    label: 'Colección',
                    fieldOptions: {
                      componentTypeName: 'collections'
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.collectionId),callback:function ($$v) {_vm.$set(_vm.indicator, "collectionId", $$v)},expression:"indicator.collectionId"}}):_vm._e()],1):_vm._e(),(_vm.indicatorType.requireField && _vm.indicator.collectionId)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicatorType.requireField && _vm.indicator.collectionId)?_c('CollectionFieldSelect',{key:"fieldName",attrs:{"name":"fieldName","schema":{
                    label: 'Campo',
                    fieldOptions: {
                      collectionId: _vm.indicator.collectionId
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.fieldName),callback:function ($$v) {_vm.$set(_vm.indicator, "fieldName", $$v)},expression:"indicator.fieldName"}}):_vm._e()],1):_vm._e(),(_vm.indicator.collectionId)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicator.collectionId)?_c('ComponentSelect',{key:"filtersIds",attrs:{"name":"filtersIds","schema":{
                    label: 'Filtros',
                    fieldOptions: {
                      multi: true,
                      componentTypeName: 'filters',
                      filterByKey: 'collectionId',
                      filterByValue: _vm.indicator.collectionId
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.filtersIds),callback:function ($$v) {_vm.$set(_vm.indicator, "filtersIds", $$v)},expression:"indicator.filtersIds"}}):_vm._e()],1):_vm._e(),(
                  _vm.indicator.collectionId &&
                  _vm.indicator.filtersIds &&
                  _vm.indicator.filtersIds.length > 1
                )?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(
                    _vm.indicator.collectionId &&
                    _vm.indicator.filtersIds &&
                    _vm.indicator.filtersIds.length > 1
                  )?_c('ComponentSelect',{key:"filterByDefault",attrs:{"name":"filterByDefault","schema":{
                    label: 'Filtro por defecto',
                    fieldOptions: {
                      componentTypeName: 'filters',
                      filterByKey: '_id',
                      filterByValue: _vm.indicator.filtersIds
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.filterByDefault),callback:function ($$v) {_vm.$set(_vm.indicator, "filterByDefault", $$v)},expression:"indicator.filterByDefault"}}):_vm._e()],1):_vm._e(),(_vm.indicator.filtersIds && _vm.indicator.filtersIds.length)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicator.filtersIds && _vm.indicator.filtersIds.length)?_c('v-checkbox',{key:"allowsNoFilter",staticClass:"pb-0 mb-0",attrs:{"label":"Se puede usar sin filtro"},model:{value:(_vm.indicator.allowsNoFilter),callback:function ($$v) {_vm.$set(_vm.indicator, "allowsNoFilter", $$v)},expression:"indicator.allowsNoFilter"}}):_vm._e()],1):_vm._e(),(_vm.indicator.filtersIds && _vm.indicator.filtersIds.length > 1)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(
                    _vm.indicator.filtersIds && _vm.indicator.filtersIds.length > 1
                  )?_c('v-checkbox',{key:"orderFiltersByName",staticClass:"pt-0 mt-0",attrs:{"label":"Ordenar filtros alfabéticamente"},model:{value:(_vm.indicator.orderFiltersByName),callback:function ($$v) {_vm.$set(_vm.indicator, "orderFiltersByName", $$v)},expression:"indicator.orderFiltersByName"}}):_vm._e()],1):_vm._e()],1)],1),(_vm.indicator.options)?_c('h1',{staticClass:"headline mt-2 font-weight-bold"},[_vm._v(" Opciones del indicador ")]):_vm._e(),_c('OptionFields',{attrs:{"schema":_vm.indicatorType.optionsParams,"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{
              environmentId: _vm.environmentId,
              collectionId: _vm.indicator.collectionId
            },"validationErrors":{}},model:{value:(_vm.indicatorOptions),callback:function ($$v) {_vm.indicatorOptions=$$v},expression:"indicatorOptions"}})],1)]:_vm._e()],2),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":"Eliminar Indicador"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.updateSavedIndicator}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }