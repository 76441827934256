
























































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import {
  IndicatorFragment,
  IndicatorUpdateFragment
} from '@/components/componentTypes/indicators/fragments'
import { Indicator, IndicatorType } from '@/models'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import OptionFields from '@/components/form/OptionFields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    ComponentSelect,
    CollectionFieldSelect,
    Loading,
    Fields,
    OptionFields
  },
  apollo: {
    savedIndicator: {
      query: gql`
        query getIndicator($indicatorId: ID) {
          savedIndicator: indicator(indicatorId: $indicatorId) {
            ...Indicator
          }
        }
        ${IndicatorFragment}
      `,
      variables() {
        return {
          indicatorId: this.componentId
        }
      }
    },
    indicatorTypes: gql`
      query {
        indicatorTypes {
          _id
          name
          optionsParams
          requireCollection
          requireField
        }
      }
    `
  }
})
export default class IndicatorEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String

  savedIndicator: Indicator | null = null
  indicatorTypes: IndicatorType[] = []
  indicator: Partial<Indicator> = {}

  saving = false

  @Watch('savedIndicator')
  updateSavedIndicator(savedIndicator: Indicator) {
    this.$set(this, 'indicator', _cloneDeep(savedIndicator))
  }

  @Watch('indicator.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  get indicatorOptions() {
    if (this.indicator.options === null) {
      return {}
    } else {
      return this.indicator.options
    }
  }

  set indicatorOptions(options) {
    this.indicator.options = options
  }

  /** Resets options on each type change so the indicator doesn't get stuck. */
  @Watch('indicator.indicatorTypeId')
  clearOptions(newType: string, oldType?: string | null) {
    if (newType === oldType || oldType == null) return
    this.indicator.options = {}
  }

  get indicatorType() {
    if (!this.indicator.indicatorTypeId) return
    return this.indicatorTypes.find(
      (t) => t._id === this.indicator.indicatorTypeId
    )
  }

  get availableIndicatorTypes() {
    return this.indicatorTypes.map((indicatorType) => ({
      label: indicatorType.name,
      value: indicatorType._id
    }))
  }

  get dirty() {
    return !_isEqual(this.indicator, this.savedIndicator)
  }

  normalize() {
    if (!this.indicatorType?.requireCollection)
      this.indicator.collectionId = null
    if (!this.indicatorType?.requireField || !this.indicator.collectionId)
      this.indicator.fieldName = null
    if (!this.indicator.collectionId) this.indicator.filtersIds = []
    if (!this.indicator.filtersIds) {
      this.indicator.allowsNoFilter = true
      this.indicator.filterByDefault = ''
    }
    if (
      !this.indicator.allowsNoFilter &&
      this.indicator.filtersIds &&
      this.indicator.filtersIds.length <= 1
    ) {
      this.indicator.filterByDefault = this.indicator.filtersIds[0]
    }
  }

  async save() {
    if (!this.indicator || this.saving) return
    this.saving = true
    try {
      this.normalize()
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($indicatorId: ID, $indicator: UpdateIndicatorInput) {
            updateIndicator(indicatorId: $indicatorId, indicator: $indicator) {
              ...Indicator
            }
          }
          ${IndicatorFragment}
        `,
        // Parameters
        variables: {
          indicatorId: this.indicator._id,
          indicator: cleanData(this.indicator, IndicatorUpdateFragment)
        }
      })

      this.savedIndicator = result.data.updateIndicator
      this.$emit('save', result.data.updateIndicator)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        '¿Seguro que quieres eliminar por completo este indicador del ambiente?'
      ))
    )
      return
    if (!this.indicator || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($indicatorId: ID) {
            deleteIndicator(indicatorId: $indicatorId)
          }
        `,
        // Parameters
        variables: {
          indicatorId: this.indicator._id
        }
      })
      this.$emit('delete', result.data.deleteIndicator)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
